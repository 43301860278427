<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'error-brdr': error,
      'success-brdr': hasValidation && !error && touched
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label">
        <span>{{ label }}</span>
        <i v-if="required" class="required-field float-left" />
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <input
          v-if="onKeyPress"
          ref="input"
          v-focus="focus"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-control"
          @keypress="onKeyPress"
        />
        <input
          v-else
          ref="input"
          v-focus="focus"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          :style="inputStyle"
          class="form-control"
        />
      </slot>
      <span class="success-icon" v-if="hasValidation && !error && touched">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BaseCustomInput',
  props: {
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number] },
    onKeyPress: { type: Function },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: true },
    inputStyle: String
  },
  model: { prop: 'value', event: 'input' },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) this.touched = true;
      this.$emit('input', evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.input.focus();
    }
  },
  directives: {
    focus: {
      inserted: function(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
</style>
