const config = {
  NODE_ENV: 'production',
  MCAST_API: 'https://api.managecast.com/api/v1'
};
let hostname = window.location.hostname;
if (hostname.indexOf('internal-portal') != -1) {
  config.MCAST_API = 'https://internal-api.managecast.com/api/v1';
} else if (hostname.indexOf('localhost') !== -1 || hostname.indexOf('local') !== -1) {
  config.NODE_ENV = 'development';
  config.MCAST_API = 'http://localhost:4040/api/v1';
}
console.log(config);
export default config;
