import store from '../../store';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { MONITORING } from '@/common/data/constants';
// const USER_TOKEN_NAME = 'user-token';
const URL_TO_REDIRECT_AFTER_LOGIN = 'urlToRedirectAfterLogin';

// Layouts
import DashboardLayout from 'src/monitoring/pages/Layout/DashboardLayout';
// Default
const Forbidden = () =>
  import(/* webpackChunkName: "MonitoringForbidden" */ 'src/common/pages/Forbidden');

// User
const Profile = () => import(/* webpackChunkName: "Profile" */ 'src/common/pages/User/Profile');
const UserSubscriptions = () => import(/* webpackChunkName: "UserSubscriptions" */ 'src/common/pages/User/UserSubscriptions.vue');
const ChangePassword = () =>
  import(/* webpackChunkName: "ChangePassword" */ 'src/common/pages/User/ChangePassword');
const ForceSecurityQuestions = () =>
  import(/* webpackChunkName: "ForceSecurityQuestions" */ 'src/common/pages/User/ForceSecurityQuestions');
const SingleSignOn = () =>
  import(/* webpackChunkName: "Dashboard" */ 'src/monitoring/pages/User/SingleSignOn');

// Dashboard
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ 'src/monitoring/pages/Dashboard/Dashboard');
// Users
const Users = () => import(/* webpackChunkName: "Users" */ 'src/common/pages/Users/Users');
const UserLogs = () => import(/* webpackChunkName: "Users" */ 'src/common/pages/Users/UserLogs');
//Daily checks
const DailyChecks = () =>
  import(/* webpackChunkName: "DailyChecks" */ 'src/monitoring/pages/DailyChecks/Layout');
//Downloads
const Downloads = () =>
  import(/* webpackChunkName: "Downloads" */ 'src/monitoring/pages/Downloads');
//Downloads
const Support = () => import(/* webpackChunkName: "Support" */ 'src/monitoring/pages/Support');
const LiveSupport = () =>
  import(/* webpackChunkName: "LiveSupport" */ 'src/monitoring/pages/LiveSupport');

const Companies = () =>
  import(/* webpackChunkName: "Companies" */ 'src/common/pages/Companies/Companies');
const Rules = () => import(/* webpackChunkName: "Rules" */ 'src/monitoring/pages/Rules/Rules');
const CalendarReport = () =>
  import(/* webpackChunkName: "CalendarReport" */ 'src/monitoring/pages/CalendarReport/CalendarReport');
const Licensing = () =>
  import(/* webpackChunkName: "Licensing" */ 'src/monitoring/pages/Licensing/Licensing');
const ReportSettings = () =>
  import(/* webpackChunkName: "ReportSettings" */ 'src/monitoring/pages/ReportSettings/ReportSettings');
const Trends = () =>
  import(/* webpackChunkName: "AnalyticsReport" */ 'src/monitoring/pages/Analytics/Trends');
const Hints = () => import(/* webpackChunkName: "Hints" */ 'src/monitoring/pages/Hints/Hints');

const isUserAuthenticated = to => {
  if (!store.getters.isAuthenticated) {
    if (to && to.name !== 'Session') {
      localStorage.setItem(URL_TO_REDIRECT_AFTER_LOGIN, JSON.stringify(to));
    }
    return false;
  }
  return true;
};

const validateUserSession = (to, from, next) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  next();
};

const validateUserSessionAndRol = (to, from, next, page) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  const user = store.getters.loggedInUser;
  if (!UI_ABILITIES.check(user.acl, page)) {
    return next({ name: 'MonitoringForbidden' });
  }
  next();
};

const validateSupportConfig = (to, from, next, page) => {
  if (!store.getters.brandingGetter.features) {
    store.dispatch('GET_BRANDING').then(() => {
      if (!store.getters.brandingGetter?.features?.supportSection?.links[page]) {
        return next({name: 'MonitoringForbidden'});
      }
      next();
    })
  } else {
    if (!store.getters.brandingGetter?.features?.supportSection?.links[page]) {
      return next({name: 'MonitoringForbidden'});
    }
  }
  next();
}

const routes = [
  {
    path: '/',
    redirect: `/${MONITORING}`,
    name: 'Home'
  },
  {
    path: `/${MONITORING}`,
    component: DashboardLayout,
    redirect: `/${MONITORING}/dashboard`,
    name: 'RootMonitoring',
    beforeEnter: validateUserSession,
    children: [
      {
        path: `dashboard`,
        name: 'Dashboard',
        meta: { showFooter: true },
        components: { default: Dashboard },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.dashboard')
      },
      {
        path: `users`,
        name: 'Users',
        components: { default: Users },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.users')
      },
      {
        path: `users-logs`,
        name: 'UserLogs',
        components: { default: UserLogs },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.userLogs')
      },
      {
        path: `rules`,
        name: 'Rules',
        components: { default: Rules },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.rules')
      },
      {
        path: `companies`,
        name: 'Companies',
        components: { default: Companies },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'common.companies')
      },
      {
        path: `/apps/:provider`,
        name: 'SingleSignOn',
        component: SingleSignOn
      },
      {
        path: `profile`,
        name: 'Profile',
        components: { default: Profile }
      },
      {
        path: `subscriptions`,
        name: 'Subcriptions',
        components: { default: UserSubscriptions }
      },
      {
        path: `change-password`,
        name: 'ChangePassword',
        components: { default: ChangePassword }
      },
      {
        path: `reset-password`,
        name: 'ResetPassword',
        components: { default: ChangePassword }
      },
      {
        path: `security-questions`,
        name: 'SecurityQuestions',
        components: { default: ForceSecurityQuestions }
      },
      {
        path: `daily-checks`,
        name: 'DailyChecks',
        components: { default: DailyChecks },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.daily-checks')
      },
      {
        path: `calendar-report`,
        name: 'CalendarReport',
        components: { default: CalendarReport },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.calendar-report')
      },
      {
        path: `licensing`,
        name: 'Licensing',
        components: { default: Licensing },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.licensing')
      },
      {
        path: `report-settings`,
        name: 'Report Settings',
        components: { default: ReportSettings },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.report-settings')
      },
      {
        path: `forbidden`,
        name: 'MonitoringForbidden',
        components: { default: Forbidden }
      },
      {
        path: `downloads`,
        name: 'Downloads',
        components: { default: Downloads },
        beforeEnter: (to, from, next) => validateSupportConfig(to, from, next, 'downloads')

      },
      {
        path: `support/request`,
        name: 'SupportRequest',
        components: { default: Support },
        beforeEnter: (to, from, next) => validateSupportConfig(to, from, next, 'request')
      },
      {
        path: `support/live`,
        name: 'LiveSupport',
        components: { default: LiveSupport },
        beforeEnter: (to, from, next) => validateSupportConfig(to, from, next, 'live')
      },
      {
        path: `analytics/trends`,
        name: 'Trends',
        components: { default: Trends },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.trends')
      },
      {
        path: `hints`,
        name: 'Hints',
        components: { default: Hints },

        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'monitoring.hints')
      }
    ]
  }
];

export default routes;
