import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

const SET_SUBSCRIPTIONS = 'SUBSCRIPTIONS/SET_SUBSCRIPTIONS';
const SET_USER_SUBSCRIPTIONS = 'SUBSCRIPTIONS/SET_USER_SUBSCRIPTIONS';

const SUBSCRIPTIONS_ENDPOINT = `subscriptions`;
const USER_SUBSCRIPTIONS_ENDPOINT = `subscriptions/:userId`;
const UPDATE_USAGE_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/billing/:usageId`;
const ACKNOWLEDGE_USAGE_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/billing/:usageId/ack`;
const CANCEL_SUBSCRIPTION_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/cancel/:companyId`;
const UPDATE_SUBSCRIPTION_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/update/:companyId`;
const UPDATE_TRIAL_END_DATE_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/update-trial/:companyId`;
const CONFIRM_DELETION_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/delete-company`;
const FORGIVE_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/forgive-company`;
const ROLLBACK_ENDPOINT = `${SUBSCRIPTIONS_ENDPOINT}/rollback/:companyId`;

function initialState() {
  return {
    subscriptions: [],
    userSubscriptions: []
  };
}

const state = initialState();

// actions
const actions = {
  GET_USER_SUBSCRIPTIONS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(USER_SUBSCRIPTIONS_ENDPOINT.replace(':userId', params.userId), {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          const { data } = response;
          commit(SET_USER_SUBSCRIPTIONS, data);
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error getting subscriptions. Try again later.');
          else reject('Error getting subscriptions. Try again later.');
        });
    });
  },
  GET_SUBSCRIPTIONS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(SUBSCRIPTIONS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          const { data } = response;
          commit(SET_SUBSCRIPTIONS, data);
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error getting subscriptions. Try again later.');
          else reject('Error getting subscriptions. Try again later.');
        });
    });
  },
  UPDATE_USAGE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(UPDATE_USAGE_ENDPOINT.replace(':usageId', data.usageId), data)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error updating subscription. Try again later.');
          else reject('Error updating subscription. Try again later.');
        });
    });
  },
  ACKNOWLEDGE_USAGE({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ACKNOWLEDGE_USAGE_ENDPOINT.replace(':usageId', data.usageId), data)
        .then(response => {
          const { data } = response;
          const params = { month: moment().format('MM'), year: moment().format('YYYY') };
          dispatch('GET_SUBSCRIPTIONS', params);
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error updating subscription. Try again later.');
          else reject('Error updating subscription. Try again later.');
        });
    });
  },
  UPDATE_TRIAL_END_DATE({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(UPDATE_TRIAL_END_DATE_ENDPOINT.replace(':companyId', data.companyId), data)
        .then(response => {
          const { data } = response;
          const params = { month: moment().format('MM'), year: moment().format('YYYY') };
          dispatch('GET_SUBSCRIPTIONS', params);
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error updating subscription. Try again later.');
          else reject('Error updating subscription. Try again later.');
        });
    });
  },
  MODIFY_USAGE_LIST({ state, commit }, data) {
    try {
      const usageIdx = state.subscriptions.data.findIndex(u => u._id === data.usageId);
      const newUsage = {...state.subscriptions.data[usageIdx], ack: false, ...data}
      const newSubscriptions = state.subscriptions.data.toSpliced(usageIdx, 1, newUsage);
      commit(SET_SUBSCRIPTIONS, { data: newSubscriptions });
      return {success: true}
    } catch (error) {
      console.log({ error });
      return { success: false };
    }
  },
  CANCEL_SUBSCRIPTION(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CANCEL_SUBSCRIPTION_ENDPOINT.replace(':companyId', data.companyId), data)
        .then(() => resolve({ success: true }))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error on the proccess, try again later'
          })
        );
    });
  },
  UPDATE_SUBSCRIPTION(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(UPDATE_SUBSCRIPTION_ENDPOINT.replace(':companyId', data.companyId), data)
        .then(() => resolve({ success: true }))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error on the proccess, try again later'
          })
        );
    });
  },
  CONFIRM_DELETION({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CONFIRM_DELETION_ENDPOINT, data)
        .then(response => {
            const { data } = response;
            const params = { month: moment().format('MM'), year: moment().format('YYYY') };
            dispatch('GET_SUBSCRIPTIONS', params);
            resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error updating subscription. Try again later.');
          else reject('Error updating subscription. Try again later.');
        });
    });
  },
  FORGIVE_COMPANY({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(FORGIVE_ENDPOINT, data)
        .then(response => {
            const { data } = response;
            const params = { month: moment().format('MM'), year: moment().format('YYYY') };
            dispatch('GET_SUBSCRIPTIONS', params);
            resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error updating subscription. Try again later.');
          else reject('Error updating subscription. Try again later.');
        });
    });
  },
  ROLLBACK_COMPANY_SUBSCRIPTION(context, data) {
    // subscription route for rollback
    return new Promise((resolve, reject) => {
      axios
        .post(ROLLBACK_ENDPOINT.replace(':companyId', data.companyId), data)
        .then(() => resolve({ success: true }))
        .catch(error =>
          reject({
            success: false,
            message: error.response.data.message || 'There was an error on the proccess, try again later'
          })
        );
    });
  }
};
//FORGIVE_ENDPOINT
const getters = {
  getSubscriptions: state => state.subscriptions,
  getUserSubscriptions: state => state.userSubscriptions
};

const mutations = {
  [SET_SUBSCRIPTIONS](state, data) {
    state.subscriptions = data;
  },
  [SET_USER_SUBSCRIPTIONS](state, data) {
    state.userSubscriptions = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
