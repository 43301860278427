var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDashboardLayout',[_c('side-bar',{attrs:{"slot":"sidebar","background-color":_vm.sidebarBackground,"title":_vm.sidebarTitle,"logo":_vm.sidebarLogo,"logoHeight":_vm.logoHeight,"logoWidth":_vm.logoWidth},slot:"sidebar",scopedSlots:_vm._u([{key:"links",fn:function(props){return [(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.dashboard'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'tim-icons icon-chart-bar-32',
          path: `/${_vm.MONITORING}/dashboard`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.calendar-report'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar view',
          icon: 'tim-icons icon-calendar-60',
          path: `/${_vm.MONITORING}/calendar-report`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.daily-checks'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Daily checks',
          icon: 'tim-icons icon-sound-wave',
          path: `/${_vm.MONITORING}/daily-checks`
        }}}):_vm._e(),(
          _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.rules') &&
            _vm.brandingGetter.features.rules.enabled
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Rules',
          icon: 'fas fa-asterisk',
          path: `/${_vm.MONITORING}/rules`
        }}}):_vm._e(),(
          _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.hints') &&
            _vm.brandingGetter.features.hints.enabled
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Hints',
          icon: 'fas fa-clipboard',
          path: `/${_vm.MONITORING}/hints`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.users'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'tim-icons icon-single-02',
          path: `/${_vm.MONITORING}/users`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'common.companies'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Companies',
          icon: 'tim-icons icon-bank',
          path: `/${_vm.MONITORING}/companies`
        }}}):_vm._e(),(
          _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.reporting') &&
            _vm.brandingGetter.features.reporting.enabled
        )?_c('sidebar-item',{attrs:{"link":{ name: 'Reports', icon: 'tim-icons icon-book-bookmark' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.userLogs'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Users Logs',
            path: `/${_vm.MONITORING}/users-logs`
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.licensing'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Licensing',
            path: `/${_vm.MONITORING}/licensing`
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.report-settings'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Scheduled',
            path: `/${_vm.MONITORING}/report-settings`
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.trends'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Analytics',
            icon: 'tim-icons icon-book-bookmark'
          },"isMenuAndHasParent":""}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'monitoring.trends'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Trends',
              path: `/${_vm.MONITORING}/analytics/trends`
            }}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.brandingGetter.features?.supportSection?.enabled)?_c('sidebar-item',{attrs:{"link":{ name: 'Support', icon: 'tim-icons icon-support-17' }}},[(_vm.brandingGetter.features?.supportSection?.links?.request)?_c('sidebar-item',{attrs:{"link":{
            name: 'Request',
            path: `/${_vm.MONITORING}/support/request`
          }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.links?.live)?_c('sidebar-item',{attrs:{"link":{
            name: 'Live',
            path: `/${_vm.MONITORING}/support/live`
          }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.links?.downloads)?_c('sidebar-item',{attrs:{"link":{
            name: 'Downloads',
            path: `/${_vm.MONITORING}/downloads`
          }}}):_vm._e()],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }