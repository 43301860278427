var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDashboardLayout',[_c('side-bar',{attrs:{"slot":"sidebar","background-color":_vm.sidebarBackground,"title":_vm.sidebarTitle,"logo":_vm.sidebarLogo,"logoHeight":_vm.logoHeight,"logoWidth":_vm.logoWidth},slot:"sidebar",scopedSlots:_vm._u([{key:"links",fn:function(props){return [(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.dashboard'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'tim-icons icon-chart-bar-32',
          path: `/${_vm.O365}/dashboard`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.jobs'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Jobs',
          icon: 'fab fa-windows',
          path: `/${_vm.O365}/jobs`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.organizations'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Organizations',
          icon: 'tim-icons icon-bank',
          path: `/${_vm.O365}/organizations`
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.repositories'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Repositories',
          icon: 'tim-icons icon-cloud-download-93',
          path: `/${_vm.O365}/repositories`
        }}}):_vm._e(),(
          _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.reporting') &&
            _vm.brandingGetter.features.reporting.enabled
        )?_c('sidebar-item',{attrs:{"link":{ name: 'Reports', icon: 'tim-icons icon-book-bookmark' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.licensing'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Licensing',
            path: `/${_vm.O365}/licensing`
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.licensing'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Licensed Users',
            path: `/${_vm.O365}/license-consumption`
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Restore Operators',
          icon: 'fas fa-user-lock',
          path: `/${_vm.O365}/restore-operator-roles`
        }}}),(_vm.restoreUrl)?_c('sidebar-item',{attrs:{"isExternalLink":"","link":{
          name: 'Restore',
          icon: 'far fa-window-restore',
          path: _vm.restoreUrl,
          target: '_blank'
        }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.restorePortal') && _vm.showRestore)?_c('sidebar-item',{attrs:{"link":{ name: 'Restore (BETA)', icon: 'fas fa-window-restore' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.restoreOutlook') && _vm.restorePortal?.outlook)?_c('sidebar-item',{attrs:{"link":{
            name: 'Outlook',
            path: `/${_vm.O365}/outlook`,
            icon: 'icon-restore fas fa-square-envelope fa-xl'
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.restoreOnedrive') && _vm.restorePortal?.onedrive)?_c('sidebar-item',{attrs:{"link":{
            name: 'OneDrive',
            path: `/${_vm.O365}/onedrive`,
            icon: 'icon-restore fas fa-cloud fa-xl'
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.restoreSharepoint') && _vm.restorePortal?.sharepoint)?_c('sidebar-item',{attrs:{"link":{
            name: 'SharePoint',
            path: `/${_vm.O365}/sharepoint`,
            icon: 'icon-restore fas fa-s fa-xl'
          }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.restoreTeams') && _vm.restorePortal?.teams)?_c('sidebar-item',{attrs:{"link":{
            name: 'Teams',
            path: `/${_vm.O365}/teams`,
            icon: 'icon-restore fas fa-user-group fa-xl'
          }}}):_vm._e()],1):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'o365.subscriptions') && _vm.hasStripe)?_c('sidebar-item',{attrs:{"link":{
          name: 'Billing',
          icon: 'fas fa-money-bill',
          path: `/${_vm.O365}/billing`,
        }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.enabled)?_c('sidebar-item',{attrs:{"link":{ name: 'Support', icon: 'tim-icons icon-support-17' }}},[(_vm.brandingGetter.features?.supportSection?.links?.request)?_c('sidebar-item',{attrs:{"link":{
            name: 'Request',
            path: `/${_vm.MONITORING}/support/request`
          }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.links?.live)?_c('sidebar-item',{attrs:{"link":{
            name: 'Live',
            path: `/${_vm.MONITORING}/support/live`
          }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.links?.downloads)?_c('sidebar-item',{attrs:{"link":{
            name: 'Downloads',
            path: `/${_vm.MONITORING}/downloads`
          }}}):_vm._e(),(_vm.brandingGetter.features?.supportSection?.links?.faq?.enabled)?_c('sidebar-item',{attrs:{"isExternalLink":"","link":{
            name: 'FAQ',
            path: _vm.brandingGetter.features?.supportSection?.links?.faq?.url,
            target: '_blank'
          }}}):_vm._e()],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }